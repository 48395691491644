<template>
  <v-container class="align-stretch layout-container">
    <BaseHeader
      :title="$t('ai-assistant.title')"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('ai-forge.assistant-section.subtitle') }}</p>
      </template>
    </BaseHeader>

    <v-card
      class="mt-4"
      outlined
    >
      <v-card-title>
        {{ $t('ai-forge.assistant-section.title') }}
        <v-badge
          content="BETA"
          color="orange"
          class="ml-4"
        />
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('ai-forge.assistant-section.body-texts.0') }}
        </p>
        <p>
          {{ $t('ai-forge.assistant-section.body-texts.1') }} <br> <br>

          {{ $t('ai-forge.assistant-section.body-texts.2') }}:
        </p>

        <AskAi id="kOrFVyoS4cjhDwTmWuyHDT1A0kOuXc" />

        <v-alert
          type="info"
          icon="mdi-lightbulb-on-20"
          class="mt-4 body-2"
          text
        >
          <span
            v-html="$t('alerts.text-ai.info-beta')"
          />
          <br>
          <v-btn
            color="secondary"
            class="mt-4"
            href="https://survey.zohopublic.eu/zs/5ADH3b"
            target="_blank"
            rel="noopener noreferrer"
            small
            outlined
          >
            {{ $t('buttons.ai-forge.feedback') }} …
          </v-btn>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/performanceReport/HeaderDesign.vue'
import AskAi from '@/components/AskAi.vue'

export default {
  components: {
    BaseHeader,
    HeaderDesign,
    AskAi
  }
}
</script>
