<template>
  <v-container class="align-stretch layout-container">
    <BaseHeader
      :title="$t('ai-forge.title')"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('ai-forge.subtitle') }}</p>
      </template>
    </BaseHeader>

    <v-card outlined>
      <v-card-title>
        {{ $t('ai-forge.booked-section.title') }}
        <v-badge
          content="BETA"
          color="orange"
          class="ml-4"
        />
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('ai-forge.booked-section.subtitle') }}
        </p>
        <p>
          {{ $t('ai-forge.booked-section.title-text-kind') }}:
          <ul>
            <li>{{ $t('text-ai.text-kind.facebook.label') }}</li>
            <li>{{ $t('text-ai.text-kind.instagram.label') }}</li>
            <li>{{ $t('text-ai.text-kind.linkedin.label') }}</li>
            <li>{{ $t('text-ai.text-kind.facebook-ads.label') }}</li>
            <li>{{ $t('text-ai.text-kind.customer-mailing.label') }}</li>
            <li>{{ $t('text-ai.text-kind.website-text.label') }}</li>
            <li>{{ $t('text-ai.text-kind.gmb-post.label') }}</li>
            <li>{{ $t('text-ai.text-kind.gmb-product.label') }}</li>
          </ul>
        </p>
        <p>
          {{ $t('ai-forge.booked-section.description.prefix') }} <router-link to="/landingpages">
            {{ $t('navigation.labels.landingpages') }}
          </router-link>
          {{ $t('ai-forge.booked-section.description.suffix') }}
        </p>
      </v-card-text>
    </v-card>

    <Feature :feature-slug="featureNames.ASKAI_ASSISTENT">
      <v-card
        class="mt-4"
        outlined
      >
        <v-card-title>
          {{ $t('ai-forge.assistant-section.title') }}
          <v-badge
            content="BETA"
            color="orange"
            class="ml-4"
          />
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t('ai-forge.assistant-section.body-texts.0') }}
          </p>
          <p>
            {{ $t('ai-forge.assistant-section.body-texts.1') }} <br> <br>

            {{ $t('ai-forge.assistant-section.body-texts.2') }}:
          </p>

          <AskAi id="kOrFVyoS4cjhDwTmWuyHDT1A0kOuXc" />

          <v-alert
            type="info"
            icon="mdi-lightbulb-on-20"
            class="mt-4 body-2"
            text
          >
            <span
              v-html="$t('alerts.text-ai.info-beta')"
            />
            <br>
            <v-btn
              color="secondary"
              class="mt-4"
              href="https://survey.zohopublic.eu/zs/5ADH3b"
              target="_blank"
              rel="noopener noreferrer"
              small
              outlined
            >
              {{ $t('buttons.ai-forge.feedback') }} …
            </v-btn>
          </v-alert>
        </v-card-text>
      </v-card>
    </Feature>
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/performanceReport/HeaderDesign.vue'
import AskAi from '@/components/AskAi.vue'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    BaseHeader,
    HeaderDesign,
    AskAi
  },
  mixins: [featureMixin]
}
</script>
